
import GLOBAL from "../constants/global";

export default {
  name: "IndexPage",
  head() {
    // console.log('GLOBAL',GLOBAL);
    let templates = [
      (stockName, target, stop_loss) =>
        `Our dynamic technical analysis strongly indicates that ${stockName} is poised for a remarkable ascent to a target price of ${target}, while implementing an essential stop loss at ${stop_loss}.`,
      (stockName, target, stop_loss) =>
        `According to our robust technical analysis, ${stockName} is primed for an explosive surge to a target price of ${target}, strategically protected by a vital stop loss at ${stop_loss}.`,
      (stockName, target, stop_loss) =>
        `Our cutting-edge technical analysis confidently predicts that ${stockName} is on the verge of a substantial surge to a target price of ${target}, safeguarded by a critical stop loss at ${stop_loss}.`,
      (stockName, target, stop_loss) =>
        `With our expert technical analysis at the helm, ${stockName} is forecasted to achieve an impressive target price of ${target}, fortified by a crucial stop loss at ${stop_loss}.`,
      (stockName, target, stop_loss) =>
        `Harnessing the insights from our advanced technical analysis, ${stockName} is set to soar to a target price of ${target}, while ensuring a safeguarding stop loss at ${stop_loss}.`,
      (stockName, target, stop_loss) =>
        `Backed by our astute technical analysis, ${stockName} is projected to skyrocket to a target price of ${target}, with a protective stop loss at ${stop_loss}.`,
      (stockName, target, stop_loss) =>
        `Our forward-looking technical analysis confidently anticipates ${stockName}'s ascent to a target price of ${target}, backed by a pivotal stop loss at ${stop_loss}.`,
      (stockName, target, stop_loss) =>
        `With unwavering conviction from our technical analysis, ${stockName} is expected to surge to a target price of ${target}, with a critical stop loss at ${stop_loss}.`,
      (stockName, target, stop_loss) =>
        `Employing our strategic technical analysis, ${stockName} is positioned for a compelling rise to a target price of ${target}, safeguarded by a crucial stop loss at ${stop_loss}.`,
      (stockName, target, stop_loss) =>
        `Utilizing the foresight of our technical analysis, ${stockName} is on track to achieve an impressive target price of ${target}, while maintaining a protective stop loss at ${stop_loss}.`,
    ];
    let listOfStockItems = [];
    for (let i = 0; i < this.stocks.length; i++) {
      const stockItem = this.stocks[i];
      listOfStockItems.push({
        "@type": "ListItem",
        name: `${stockItem.name}`,
        position: i + 1,
        url: `${GLOBAL.url}/chart?symbol=BSE:${stockItem.symbol}`, // Add a specific URL for this stock pick
        description: templates[i % 10](
          stockItem.name,
          stockItem.target,
          stockItem.stop_loss
        ),
        "custom:targetPrice": {
          "@type": "MonetaryAmount",
          currency: "INR",
          value: `${stockItem.target}`,
        },
        "custom:stopLossPrice": {
          "@type": "MonetaryAmount",
          currency: "INR",
          value: `${stockItem.stop_loss}`,
        },
      });
    }
    const structuredData = {
      "@context": "http://schema.org",
      "@type": "ItemList",
      name: "Best stocks to buy today | Target and Stoploss | Invest Guru India",
      description:
        "Best stocks to buy today india for short-term | Today top share",
      url: `${GLOBAL.url}`, // Add the URL of your stock picks page
      image: `${GLOBAL.socialImage}`, // Add an image URL representing stock picks
      numberOfItems: this.stocks.length, // Update the number of items in the list
      itemListElement: listOfStockItems,
    };
    return {
      titleTemplate: "Best stocks to buy today india for short-term | %s",
      title: `${GLOBAL.company}`,
      meta: [
        {
          hid: "description",
          name: "description",
          content:
            "Invest guru | Best Stock to buy | target and stoploss | Today top share | Free",
        },
      ],
      script: [
        {
          innerHTML: JSON.stringify(structuredData),
          type: "application/ld+json",
        },
      ],
      __dangerouslyDisableSanitizers: ["script"],
    };
  },
  data() {
    return {
      stocks: [],
      loading: true,
    };
  },
  async fetch() {
    this.loading = true;
    try {
      let { data } = await this.$axios.get("/get/stocks/latest");
      // console.log("data from fetch", data);
      this.stocks = data;
    } catch (error) {
      // console.log("error from fetch", error);
      this.stocks = [];
      this.$nuxt.context.store.commit("ui/SET_MSGS", {
        title: "Internal Server Error (500)",
        description:
          "Server has encountered an unexpected condition or configuration problem ",
        color: "var(--v-error-base)",
        icon: "line-md:emoji-frown",
        position: "bottom-left",
      });
    }
    this.loading = false;
  },
};
